<template>
  <div class="background">
    <Addresses />
  </div>
</template>

<script>
import Addresses from "@/components/elements/dashboard/addresses/Addresses";

export default {
  name: "DocumentManagementAddresses",

  components: {
    Addresses,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
