<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="addresses"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="addressesPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Addresses</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('address_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Address
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- City -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.city"
                              :rules="cityRules"
                              label="City"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  City <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- country -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.country"
                              :rules="countryRules"
                              label="Country"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Country <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Line 1 -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.line_1"
                              :rules="line1Rules"
                              label="Line 1"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Line 1 <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Line 2 -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.line_2"
                              :rules="line2Rules"
                              label="Line 2"
                            >
                              <template v-slot:label>
                                <p class="body-1">Line 2</p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Postal Code -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.postal_code"
                              :rules="postalCodeRules"
                              label="Postal Code"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Postal Code <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Province -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedAddress.province"
                              :rules="provinceRules"
                              label="Province"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Province <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this address?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="btn btn-primary" @click="deleteAddressConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('address_update')"
              small
              class="mr-2"
              @click="editAddress(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('address_delete')"
              small
              @click="deleteAddress(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Addresses per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementAddresses",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "City", value: "city" },
        { text: "Country", value: "country" },
        { text: "Line 1", value: "line_1" },
        { text: "Line 2", value: "line_2" },
        { text: "Postal Code", value: "postal_code" },
        { text: "Province", value: "province" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      addresses: [],

      editedIndex: -1,
      editedAddress: {
        id: 0,
        city: "",
        country: "",
        line_1: "",
        line_2: "",
        postal_code: "",
        province: "",
        created_at: "",
        updated_at: "",
      },
      defaultAddress: {
        id: 0,
        city: "",
        country: "",
        line_1: "",
        line_2: "",
        postal_code: "",
        province: "",
        created_at: "",
        updated_at: "",
      },

      // city
      cityRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // country
      countryRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // line1
      line1Rules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // line2
      line2Rules: [
        // (v) =>
        //   /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
        //   "Only english letters, apostrophes and hyphens are allowed",
        // (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // postalCode
      postalCodeRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9']+$/.test(v) || "Only 0 to 9 is allowed",
        (v) => v.length <= 5 || "Can not be more than 5 numbers",
      ],

      // province
      provinceRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      page: 1,
      addressesPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1 ? "New Address" : "Edit Address";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedAddresses();
    },

    // addressesPerPage
    addressesPerPage() {
      this.getApiPagedAddresses();
    },

    // perPageChoice
    perPageChoice(val) {
      this.addressesPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("address_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // this.getApiAddresses();
    this.getApiPagedAddresses();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiAddress();
        } else {
          this.createApiAddress();
        }
      }
    },

    // editAddress
    editAddress(address) {
      this.editedIndex = this.addresses.indexOf(address);
      this.editedAddress = Object.assign({}, address);
      this.dialog = true;
    },

    // deleteAddress
    deleteAddress(address) {
      this.editedIndex = this.addresses.indexOf(address);
      this.editedAddress = Object.assign({}, address);
      this.dialogDelete = true;
    },

    // deleteAddressConfirm
    deleteAddressConfirm() {
      this.deleteApiAddress();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedAddress = Object.assign({}, this.defaultAddress);
        this.editedIndex = -1;
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedAddress = Object.assign({}, this.defaultAddress);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiAddresses
    async getApiAddresses() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("addresses");

      if (res.status == 200) {
        this.addresses = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedAddresses
    async getApiPagedAddresses() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `addresses/paged?page_no=${this.page}&page_size=${this.addressesPerPage}`
      );

      if (res.status == 200) {
        this.addresses = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiAddress
    async createApiAddress() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "addresses",
        {
          city: this.editedAddress.city,
          country: this.editedAddress.country,
          line_1: this.editedAddress.line_1,
          line_2: this.editedAddress.line_2,
          postal_code: this.editedAddress.postal_code,
          province: this.editedAddress.province,
        },
        "Address Created Successfully!"
      );

      if (res.status == 200) {
        this.addresses.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiAddress
    async updateApiAddress() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `addresses/${this.editedAddress.id}`,
        {
          id: this.editedAddress.id,
          city: this.editedAddress.city,
          country: this.editedAddress.country,
          line_1: this.editedAddress.line_1,
          line_2: this.editedAddress.line_2,
          postal_code: this.editedAddress.postal_code,
          province: this.editedAddress.province,
        },
        "Address Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.addresses[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiAddress
    async deleteApiAddress() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `addresses/${this.editedAddress.id}`,
        "Address Deleted Successfully!"
      );

      if (res.status == 200) {
        this.addresses.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
